<mat-card appearance="outlined" fxFlex fxLayout="column" id="questionnaire">
  <mat-card-content fxLayout="column" class="abs-nomargin">
    <div>
      <img src="assets/images/abs.png" class="abs-logo" />
    </div>

    <mat-divider [inset]="true"></mat-divider>

    @if (errorMessage) {
      <app-error-message [message]="errorMessage"></app-error-message>
    }

    <!--Fixed Header -->
    @if (data && !finishProgress && !isFinished()) {
      <div fxLayout="row">
        <div fxFlex fxLayout="column">
          <span class="abs-margin-bottom titles-forms">ABS Adviser-SubAdviser DDQ</span>
          <span>You are completing the DDQ for:</span>
          @for (fundName of data.fundNames; track fundName) {
            <span class="abs-margin-left abs-bold">{{ fundName }}</span>
          }
          <div fxLayout="row">
            <span>as of:</span>
            <span class="abs-bold abs-margin-half-horizontal">
              {{ getStartDate() | date : 'MM/dd/yyyy' }}
            </span>
            <span>to</span>
            <span class="abs-bold abs-margin-half-left">{{ data.asOfDate | date : 'MM/dd/yyyy' }}</span>
          </div>
        </div>
        <div fxFlex="none" fxLayoutAlign="center center" class="abs-margin-half-right">
          @if (!showWelcome) {
            <button mat-raised-button [matMenuTriggerFor]="menu">Print</button>
          }
          <mat-menu #menu="matMenu">
            <a [href]="getPdfUrl(tabIndex)" mat-menu-item target="_blank">This tab</a>
            <a [href]="getPdfUrl()" mat-menu-item target="_blank">All tabs</a>
          </mat-menu>
        </div>
      </div>
    }
  </mat-card-content>

  @if (isFinished()) {
    <div>
      <h4 class="abs-margin-bottom">ABS Adviser-SubAdviser DDQ</h4>
      <h4>
        This Adviser-SubAdviser Due Diligence Questionnaire is already completed. Click
        <a [href]="getPdfUrl()" target="_blank">here</a>
        to download a pdf version of the questionnaire.
      </h4>
    </div>
  }

  @if (data && !finishProgress && !isFinished() && showWelcome) {
    <mat-card-content fxLayout="column" style="overflow: auto">
      <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
        <app-ddq-adviser-welcome [data]="data"></app-ddq-adviser-welcome>
      </div>
      <div fxLayout="row" class="abs-margin-right">
        <span fxFlex></span>
        <button mat-raised-button color="primary" (click)="startQuestionnaire()">Continue</button>
      </div>
    </mat-card-content>
  }

  @if (data && finishProgress && !isFinished()) {
    <app-questionnaire-conclusion
      [questionnaire]="data"
      (cancel)="onCancelFinishQuestionnaire()"
      (errorhandler)="onError($event)"
      >
      <div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h2>ABS Adviser-SubAdviser DDQ</h2>
        </div>
        <h3>
          After finished you cannot edit the questionnaire and it will be sent to our ODD team for review.
          <br />
          Are you sure you want to finish?
        </h3>
        @for (fundName of data?.fundNames; track fundName) {
          <div class="mat-subtitle-2">{{ fundName }}</div>
        }
      </div>
    </app-questionnaire-conclusion>
  }

  @if (data && !showWelcome && !finishProgress && !isFinished()) {
    <mat-card-content class="no-scroll" fxLayout="column">
      <!-- Tabs for data filling -->
      <mat-tab-group (selectedIndexChange)="onTabChange($event)" class="no-scroll" [selectedIndex]="tabIndex">
        <mat-tab label="Overview">
          <app-ddq-adviser-overview [data]="data" (showError)="onError($event)"></app-ddq-adviser-overview>
          <app-ddq-adviser-overview-funds [data]="data" (showError)="onError($event)"></app-ddq-adviser-overview-funds>
          <app-ddq-adviser-overview-tables [data]="data" (showError)="onError($event)"></app-ddq-adviser-overview-tables>
        </mat-tab>
        <mat-tab label="Organization">
          <app-ddq-adviser-organization [data]="data" (showError)="onError($event)"></app-ddq-adviser-organization>
        </mat-tab>
        <mat-tab label="Service Providers">
          <app-ddq-adviser-service-providers [data]="data" (showError)="onError($event)"></app-ddq-adviser-service-providers>
        </mat-tab>
        <mat-tab label="Investment">
          <app-ddq-adviser-investment [data]="data" (showError)="onError($event)"></app-ddq-adviser-investment>
        </mat-tab>
        <mat-tab label="Compliance">
          <app-ddq-adviser-compliance [data]="data" (showError)="onError($event)"></app-ddq-adviser-compliance>
        </mat-tab>
        <mat-tab label="Inspections and Proceedings">
          <app-ddq-adviser-inspections [data]="data" (showError)="onError($event)"></app-ddq-adviser-inspections>
        </mat-tab>
        <mat-tab label="Audits and Recordkeeping">
          <app-ddq-adviser-audits [data]="data" (showError)="onError($event)"></app-ddq-adviser-audits>
        </mat-tab>
        <mat-tab label="Firm Info">
          <app-ddq-adviser-firm-info [data]="data" (showError)="onError($event)"></app-ddq-adviser-firm-info>
        </mat-tab>
        <mat-tab label="Transactions and Valuation">
          <app-ddq-adviser-transactions [data]="data" (showError)="onError($event)"></app-ddq-adviser-transactions>
        </mat-tab>
        <mat-tab label="Other Accounts">
          <app-ddq-adviser-other-accounts
            [data]="data"
            (showError)="onError($event)"
            (selectedTab)="onTabChange($event)"
          ></app-ddq-adviser-other-accounts>
        </mat-tab>
        <mat-tab label="Insurance">
          <app-ddq-adviser-insurance [data]="data" (showError)="onError($event)"></app-ddq-adviser-insurance>
        </mat-tab>
        <mat-tab label="Responsible Investing">
          <app-ddq-adviser-resp-investing [data]="data" (showError)="onError($event)"></app-ddq-adviser-resp-investing>
        </mat-tab>
        <mat-tab label="General Info">
          <app-ddq-adviser-general-info [data]="data" (showError)="onError($event)"></app-ddq-adviser-general-info>
        </mat-tab>
        <mat-tab label="IT/Cyber">
          <app-ddq-adviser-it-cyber [data]="data" (showError)="onError($event)"></app-ddq-adviser-it-cyber>
        </mat-tab>
        <mat-tab label="Other">
          <app-ddq-adviser-other [data]="data" (showError)="onError($event)"></app-ddq-adviser-other>
          <div fxLayout="row" fxLayoutAlign="end end" class="abs-margin-right">
            <button mat-raised-button color="primary" (click)="finishQuestionnaire()">Finish</button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  }
</mat-card>
