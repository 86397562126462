import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { Attestation } from '../../model/attestation.constants';
import { Answer, QuestionnaireStatus } from '../../model/questionnaire.model';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { QuestionnaireBaseComponent } from '../common/questionnaire-base/questionnaire-base.component';

/**
 * Component used as a base for the attestation questionnaire and review components.
 */
@Component({
  template: '',
})
export abstract class AttestationBaseComponent extends QuestionnaireBaseComponent {
  public descriptor = Attestation;

  constructor(protected route: ActivatedRoute, protected service: QuestionnaireService) {
    super(route, service);
  }

  /**
   * Get an answer in the questionnaire.
   * @param code the answer code.
   * @return the corresponding answer, or undefined if the code is unknown
   */
  public getAnswer(code: string): Answer | undefined {
    return this.data!.answers.find((answer) => answer.code === code);
  }

  /**
   * Get the list of answers code for the attestation questionnaire.
   */
  public getAnswerKeys(): string[] {
    return Object.keys(Attestation);
  }

  /**
   * Quarter period start date.
   */
  public getStartDate(): Date | null {
    if (this.data) {
      return DateTime.fromISO(this.data.asOfDate).startOf('quarter').toJSDate();
    }
    return null;
  }

  /**
   * Quarter period end date.
   */
  public getEndDate(): Date | null {
    if (this.data) {
      DateTime.fromISO(this.data.asOfDate).endOf('quarter').toJSDate();
    }
    return null;
  }

  /**
   * Check questionnaire status is Created
   */
  public isCreated(): boolean {
    return QuestionnaireStatus.CREATED === this.data?.status;
  }
}
