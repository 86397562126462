import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
/*
function getInternalAngularComponent<T>(type: any): any {
    noinspection JSNonASCIINames
    // return type.ɵdir || type.ɵcmp;
}

export function ObserveOnDestroy() {
    return (target: any) => {
        const componentDefinition = getInternalAngularComponent(target);
        if (componentDefinition) {
            const old = componentDefinition.onDestroy;
            componentDefinition.onDestroy = function (this: any) {
                const onDestroySubject = componentDestroyed(this) as Subject<void>;
                onDestroySubject.next();

                if (old !== undefined && old !== null) {
                    old();
                }
            };
        } else {
            throw new Error("Ivy and AoT must be enabled for @ObserveOnDestroy().");
        }

        function decorated(this: any) {
            const instance = Reflect.construct(target, arguments);
            instance[ON_DESTROY_SUBJECT_KEY] = new ReplaySubject(1);
            return instance;
        }

        Object.setPrototypeOf(decorated, target);
        return decorated as any;
    };
}
*/
var ON_DESTROY_SUBJECT_KEY = Symbol("ON_DESTROY_SUBJECT_KEY");
var OnDestroyMixin = /** @class */ (function () {
    function OnDestroyMixin() {
        this[ON_DESTROY_SUBJECT_KEY] = new ReplaySubject();
    }
    OnDestroyMixin.prototype.observeOnDestroy = function () {
        return this[ON_DESTROY_SUBJECT_KEY];
    };
    OnDestroyMixin.prototype.ngOnDestroy = function () {
        this.observeOnDestroy().next();
    };
    return OnDestroyMixin;
}());
export { OnDestroyMixin };
export function componentDestroyed(target) {
    var onDestroySubject = target[ON_DESTROY_SUBJECT_KEY];
    if (onDestroySubject === undefined) {
        var proto = Object.getPrototypeOf(target);
        var compInfo = proto !== undefined && proto.constructor !== undefined !== proto.constructor.name !== undefined
            ? " (component: " + proto.constructor.name + ")"
            : "";
        throw new Error("You are almost there! Please extends the base class 'OnDestroyMixin'" + compInfo + ".");
    }
    return onDestroySubject;
}
export function untilComponentDestroyed(component) {
    return function (source) { return source.pipe(takeUntil(componentDestroyed(component))); };
}
